<div *ngIf="themeStyle !== 'impian_emas' ; else elseBlock">
  <h5 class="text-center">{{ addressTitle }}</h5>
  <strong> Address</strong>
  <p>{{ mainAddress }}</p>
  <strong> Contact</strong>
  <p>{{ contact1 }} / {{ contact2 }}</p>
  <strong> Email</strong>
  <p>{{ email }}</p>

  <!-- <span><i class="fa fa-address-card fa-1x"></i></span><strong> Address</strong>
  <p>{{ mainAddress }}</p>
  <span><i class="fa fa-phone fa-1x"></i></span><strong> Contact</strong>
  <p>{{ contact1 }} / {{ contact2 }}</p>
  <span><i class="fa fa-envelope-open fa-1x"></i></span><strong> Email</strong>
  <p>{{ email }}</p> -->
</div>
<ng-template #elseBlock>
  <div id="impian-address" class="text-left" id="add-desktop">
    <p>Address: Wisma Impian Emas, No. 55, Jalan Impian Emas 5/1, Taman Impian Emas, 81300 Skudai, Johor, Malaysia</p>
    <p>Phone:  07-558 8888<br>
    Customer Service:  016-733 5588<br>
    Hours: Mon - Fri, 9am to 6pm<br>
    Email: enquiry@impianemas.my</p>
  </div>
  <div id="impian-address" class="text-center" id="add-mobile">
    <p>Address: Wisma Impian Emas, No. 55, Jalan Impian Emas 5/1, Taman Impian Emas, 81300 Skudai, Johor, Malaysia</p>
    <p>Phone:  07-558 8888<br>
    Customer Service:  016-733 5588<br>
    Hours: Mon - Fri, 9am to 6pm<br>
    Email: enquiry@impianemas.my</p>
  </div>
</ng-template>

