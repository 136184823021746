<section>
  <div class="container" id="product-view">

    <div *ngIf="prodtab === 1; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
      <app-tabs></app-tabs>
    </ng-template>
    <ng-template #elseBlock>
      <div class="card">
        <div class="container-fliud">
          <div class="wrapper row">
            <div class="details col-md-12 inn-product">
              <h3 class="product-title">{{ viewProduct.name }}</h3>
              <div [innerHTML]="viewProduct.description | safeHtml" id="prod-desc"></div>
            </div>
            <div *ngIf="viewProduct.location !== ''" class="details col-md-12 inn-product">
              <h3 class="product-title">Location</h3>
              <div [innerHTML]="viewProduct.location | safeHtml"></div>
            </div>
            <div *ngIf="viewProduct.specifications !== ''" class="details col-md-12 inn-product">
              <h3 class="product-title">Specifications</h3>
              <div [innerHTML]="viewProduct.specifications | safeHtml"></div>
            </div>
            <div *ngIf="viewProduct.floor_plan !== ''" class="details col-md-12 inn-product">
              <h3 class="product-title">Floor Plan</h3>
              <div [innerHTML]="viewProduct.floor_plan | safeHtml"></div>
            </div>
            <div *ngIf="viewProduct.gallery !== ''" class="details col-md-12 inn-product">
              <h3 class="product-title">Gallery</h3>
              <div [innerHTML]="viewProduct.gallery | safeHtml"></div>
            </div>            
            <div *ngIf="viewProduct.progress !== ''" class="details col-md-12 inn-product">
              <h3 class="product-title">Progress</h3>
              <div [innerHTML]="viewProduct.progress | safeHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
