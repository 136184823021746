import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';
import { SiteInfoService } from '../service/site-info.service';
import {SafeHtmlPipe} from "../safehtml.pipe";

@Component({
  selector: 'app-inner-link-product',
  templateUrl: './inner-link-product.component.html',
  styleUrls: ['./inner-link-product.component.css']
})
export class InnerLinkProductComponent implements OnInit {

  prodViewData: any;
  viewProduct: any;
  ralatedCategory = [];
  imgUrl = '';
  prodName = '';
  prodDesc = '';

  prodPlugin: any;
  prodtab = 0;

  constructor(private viewProductData: ContentService, private siteInfo: SiteInfoService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.prodPlugin = this.siteInfo.getInfo();
    this.prodtab = this.prodPlugin.site_info.plugins.product_projects;
    this.prodViewData = this.viewProductData.getPageContent();
    this.viewProduct = this.prodViewData.product;
    this.ralatedCategory = this.viewProduct.categories;
    this.viewProductData.setCategory(this.ralatedCategory);
    console.log(this.prodtab);
  }
}
