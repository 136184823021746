import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.css']
})
export class InquiryComponent implements OnInit {
 
  model1: NgbDateStruct;
  model2: NgbDateStruct;

  constructor() { }

  ngOnInit(): void {


  }
   addSeparatorsNF(nStr, inD, outD, sep) {
    nStr += '';
    var dpos = nStr.indexOf(inD);
    var nStrEnd = '';
    if (dpos != -1) {
      nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
      nStr = nStr.substring(0, dpos);
    }
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(nStr)) {
      nStr = nStr.replace(rgx, '$1' + sep + '$2');
    }

    return nStr + nStrEnd;
  }
   stripcomma(str){
     console.log("hello",str)
    if (str.indexOf(',') != -1)
    {
     let temp = str.split(',');
      var i = temp.length;
      var j = 0;
      var tmpStr = "";
      
      while ( i > j)
        {tmpStr += temp[j]; j++;  }
      str = tmpStr;
    } 
    return str;
  }
  xdelay(value){
    let str;

    var z=document.getElementById("form1"),
    //  a=z.textA.value, b=z.textB.value, c=z.textC.value, d=z.textD.value, x=z.resultbox.value;
    a = (<HTMLInputElement>document.getElementById("textA")).value,
    b = (<HTMLInputElement>document.getElementById("textB")).value,
    c = (<HTMLInputElement>document.getElementById("textC")).value,
    d = (<HTMLInputElement>document.getElementById("textD")).value,
    x = (<HTMLInputElement>document.getElementById("resultbox")).value;

   
   if (value == 'a'){str = a;}
   if (value == 'b'){str = b;}
   if (value == 'c'){str = c;}
   if (value == 'd'){str = d;} 
   if (value == 'x'){str = x;} 
   str = this.stripcomma(str);

   if (value == 'a'){  (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(str, '.', '.', ',');}
   if (value == 'b'){  (<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(str, '.', '.', ',');}
   if (value == 'c'){  (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(str, '.', '.', ',');}
   if (value == 'd'){  (<HTMLInputElement>document.getElementById("textD")).value  = this.addSeparatorsNF(str, '.', '.', ',');}
   if (value == 'x'){ (<HTMLInputElement>document.getElementById("resultbox")).value = this.addSeparatorsNF(str, '.', '.', ',');}
   if(value == 'x'){  setTimeout(()=>{
     this.revcall()
   },200)  }
          else      {    setTimeout(()=>{
            this.call()
          },200) }
  }
   call() {
    var z=document.getElementById("form1"),
    //  a=z.textA.value, b=z.textB.value, c=z.textC.value, d=z.textD.value;
    a = (<HTMLInputElement>document.getElementById("textA")).value,
    b = (<HTMLInputElement>document.getElementById("textB")).value,
    c = (<HTMLInputElement>document.getElementById("textC")).value,
    d = (<HTMLInputElement>document.getElementById("textD")).value,
    x = (<HTMLInputElement>document.getElementById("resultbox")).value;

    
   let as = this.stripcomma(a); (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(as, '.', '.', ',');
    let bs = this.stripcomma(b); (<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(bs, '.', '.', ',');
    let cs = this.stripcomma(c); (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(cs, '.', '.', ',');
  let  ds = this.stripcomma(d); (<HTMLInputElement>document.getElementById("textD")).value = this.addSeparatorsNF(ds, '.', '.', ',');
    //alert (as +"  "+ bs+"  "+cs+"  "+ds);
   this.calc(as,bs,cs,ds);
  }

   revcall() {
    var z=document.getElementById("form1"), 
    // a=z.textA.value, b=z.textB.value, c=z.textC.value, d=z.textD.value, x=z.resultbox.value;
    a = (<HTMLInputElement>document.getElementById("textA")).value,
    b = (<HTMLInputElement>document.getElementById("textB")).value,
    c = (<HTMLInputElement>document.getElementById("textC")).value,
    d = (<HTMLInputElement>document.getElementById("textD")).value,
    x = (<HTMLInputElement>document.getElementById("resultbox")).value;

     let bs = this.stripcomma(b); (<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(bs, '.', '.', ',');
   let cs = this.stripcomma(c); (<HTMLInputElement>document.getElementById("textC")).value= this.addSeparatorsNF(cs, '.', '.', ',');
   let ds = this.stripcomma(d); (<HTMLInputElement>document.getElementById("textD")).value= this.addSeparatorsNF(ds, '.', '.', ',');
   let xs = this.stripcomma(x);(<HTMLInputElement>document.getElementById("resultbox")).value = this.addSeparatorsNF(xs, '.', '.', ',');
      //alert (xs +"  "+ bs+"  "+cs+"  "+ds);
      this.revcalc(xs,bs,cs,ds);
  }


   calc(a, b, c, d) {
    //alert (a +"  "+ b+"  "+c+"  "+d);
      var loan = a;
      var i = b/(12 * 100);  //interest_rate
      var years = c;
      var down_payment = d;

       loan = loan - down_payment;
      var n = years * 12;
      var one = ( i * Math.pow((1 + i), n) );
      var two = ( Math.pow((1 + i), n) - 1 );
      var result = (loan * one / two);
          result = this.roundNumber(result, 2);

      var z=document.getElementById("form1");
        (<HTMLInputElement>document.getElementById("resultbox")).value = this.addSeparatorsNF(result, '.', '.', ',');
      if((<HTMLInputElement>document.getElementById("resultbox")).value == "NaN"){ (<HTMLInputElement>document.getElementById("resultbox")).value = ""; }
      //alert (result +"  "+ monthly);

    }

     revcalc(x, b, c, d) {
    //alert (x +"  "+ b+"  "+c+"  "+d);
      var monthly = x;
      var i = b/(12 * 100);  //interest_rate
      var years = c;
      var down_payment = parseFloat(d); 
      
      var n = years * 12;
      var one = ( i * Math.pow((1 + i), n) );
      var two = ( Math.pow((1 + i), n) - 1 );
      var loan = 0;
      loan = down_payment + (monthly * two / one);
        loan = this.roundNumber(loan, 2);

      var z=document.getElementById("form1");
      (<HTMLInputElement>document.getElementById("textA")).value= this.addSeparatorsNF(loan, '.', '.', ',');
      if((<HTMLInputElement>document.getElementById("textA")).value == "NaN"){ (<HTMLInputElement>document.getElementById("textA")).value= ""; }
      //alert (result +"  "+ monthly);
    }

     roundNumber(num, dec) {
      var result = Math.round(num*Math.pow(10,dec))/Math.pow(10,dec); return result;
    }

     xdelay1(value) {
      let str;
      var z=document.getElementById("form1"),
      //  a=z.textA.value, b=z.textB.value, c=z.textC.value, x=z.resultbox.value;
    a = (<HTMLInputElement>document.getElementById("textA")).value,
    b = (<HTMLInputElement>document.getElementById("textB")).value,
    c = (<HTMLInputElement>document.getElementById("textC")).value,
    x = (<HTMLInputElement>document.getElementById("resultbox")).value;

      if (value == 'a'){str = a;}
      if (value == 'b'){str = b;}
      if (value == 'c'){str = c;}
      if (value == 'x'){str = x;} 
      
      str = this.stripcomma(str);

      if (value == 'a'){(<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'b'){  (<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'c'){ (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'x'){ (<HTMLInputElement>document.getElementById("resultbox")).value= this.addSeparatorsNF(str, '.', '.', ',');}

      if(value == 'x'){   setTimeout(()=>{this.revcall1()},200) }
      else      {   setTimeout(()=>{this.call1()},200)   }

      //alert("eer");
    }
     call1() {
      var z=document.getElementById("form1"),
      //  a=z.textA.value, b=z.textB.value, c=z.textC.value;
   a = (<HTMLInputElement>document.getElementById("textA")).value,
    b = (<HTMLInputElement>document.getElementById("textB")).value,
    c = (<HTMLInputElement>document.getElementById("textC")).value;

      
     let as = this.stripcomma(a); (<HTMLInputElement>document.getElementById("textA")).value= this.addSeparatorsNF(as, '.', '.', ',');
     let bs = this.stripcomma(b); (<HTMLInputElement>document.getElementById("textB")).value= this.addSeparatorsNF(bs, '.', '.', ',');
     let cs = this.stripcomma(c); (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(cs, '.', '.', ',');
      //alert (as +"  "+ bs+"  "+cs+"  "+ds);
      this.calc1(as,bs,cs);
    }

     revcall1() {
      var z=document.getElementById("form1"),
      //  a=z.textA.value, b=z.textB.value, c=z.textC.value, x=z.resultbox.value;

      a = (<HTMLInputElement>document.getElementById("textA")).value,
      b = (<HTMLInputElement>document.getElementById("textB")).value,
      c = (<HTMLInputElement>document.getElementById("textC")).value,
      x = (<HTMLInputElement>document.getElementById("resultbox")).value;
      
      
     let bs = this.stripcomma(b); (<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(bs, '.', '.', ',');
     let cs = this.stripcomma(c); (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(cs, '.', '.', ',');
     let xs = this.stripcomma(x); (<HTMLInputElement>document.getElementById("resultbox")).value = this.addSeparatorsNF(xs, '.', '.', ',');
        //alert (xs +"  "+ bs+"  "+cs+"  "+ds);
        this.revcalc1(xs,bs,cs);
    }

     calc1(a, b, c) {
    //alert (a +"  "+ b+"  "+c+"  "+d);
      var loan = a;
      var i = b/(12 * 100);  //interest_rate
      var down_payment = 0;

     loan = loan - down_payment;
      var n = c;
      var one = ( i * Math.pow((1 + i), n) );
      var two = ( Math.pow((1 + i), n) - 1 );
      var result = (loan * one / two);
          result = this.roundNumber(result, 2);

      var z=document.getElementById("form1");
        (<HTMLInputElement>document.getElementById("resultbox")).value  = this.addSeparatorsNF(result, '.', '.', ',');
      if((<HTMLInputElement>document.getElementById("resultbox")).value  == "NaN"){ (<HTMLInputElement>document.getElementById("resultbox")).value  = ""; }
      //alert (result +"  "+ monthly);

    }

     revcalc1(x, b, c) {
    //alert (x +"  "+ b+"  "+c+"  "+d);
      var monthly = x;
      var i = b/(12 * 100);  //interest_rate
      var down_payment = 0;
      
      var n = c;
      var one = ( i * Math.pow((1 + i), n) );
      var two = ( Math.pow((1 + i), n) - 1 );
      var loan = (monthly * two / one) + down_payment;
          loan = this.roundNumber(loan, 2);

      var z=document.getElementById("form1");
        (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(loan, '.', '.', ',');
      if((<HTMLInputElement>document.getElementById("textA")).value == "NaN"){ (<HTMLInputElement>document.getElementById("textA")).value = ""; }
      //alert (result +"  "+ monthly);
    }
     xdelay2(value) {
      let str;
      var z=document.getElementById("form1"), 
      // a=z.textA.value, b=z.textB.value, c=z.textC.value, x=z.resultbox.value;
      a = (<HTMLInputElement>document.getElementById("textA")).value,
      b = (<HTMLInputElement>document.getElementById("textB")).value,
      c = (<HTMLInputElement>document.getElementById("textC")).value,
      x = (<HTMLInputElement>document.getElementById("resultbox")).value;

      if (value == 'a'){str = a;}
      if (value == 'b'){str = b;}
      if (value == 'c'){str = c;}
      if (value == 'x'){str = x;} 
      
      str = this.stripcomma(str);

      if (value == 'a'){  (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'b'){  (<HTMLInputElement>document.getElementById("textB")).value= this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'c'){ (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'x'){ (<HTMLInputElement>document.getElementById("resultbox")).value = this.addSeparatorsNF(str, '.', '.', ',');}

      if(value == 'x'){   setTimeout(()=>{this.revcall2()},200) }
      else      {  setTimeout(()=>{this.call2()},200)   }

      //alert("eer");
    }
     call2() {
      var z=document.getElementById("form1"),
      //  a=z.textA.value, b=z.textB.value, c=z.textC.value;

       a = (<HTMLInputElement>document.getElementById("textA")).value,
       b = (<HTMLInputElement>document.getElementById("textB")).value,
       c = (<HTMLInputElement>document.getElementById("textC")).value;
      
     let as = this.stripcomma(a); (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(as, '.', '.', ',');
     let bs = this.stripcomma(b); (<HTMLInputElement>document.getElementById("textB")).value= this.addSeparatorsNF(bs, '.', '.', ',');
     let cs = this.stripcomma(c); (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(cs, '.', '.', ',');
      //alert (as +"  "+ bs+"  "+cs+"  "+ds);
      this.calc2(as,bs,cs);
    }

     revcall2() {
      var z=document.getElementById("form1"), 
      // a=z.textA.value, b=z.textB.value, c=z.textC.value, x=z.resultbox.value;
      a = (<HTMLInputElement>document.getElementById("textA")).value,
      b = (<HTMLInputElement>document.getElementById("textB")).value,
      c = (<HTMLInputElement>document.getElementById("textC")).value,
      x = (<HTMLInputElement>document.getElementById("resultbox")).value;
      
     let bs = this.stripcomma(b); (<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(bs, '.', '.', ',');
     let cs = this.stripcomma(c); (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(cs, '.', '.', ',');
     let xs = this.stripcomma(x); (<HTMLInputElement>document.getElementById("resultbox")).value = this.addSeparatorsNF(xs, '.', '.', ',');
        //alert (xs +"  "+ bs+"  "+cs+"  "+ds);
       this.revcalc2(xs,bs,cs);
    }

     calc2(a, b, c) {
    //alert (a +"  "+ b+"  "+c+"  "+d);
      var loan = a;
      var i = b/(12 * 100);  //interest_rate
      var years = c;
      var down_payment = 0;

       loan = loan - down_payment;
      var n = years * 12;
      var one = ( i * Math.pow((1 + i), n) );
      var two = ( Math.pow((1 + i), n) - 1 );
      var result = (loan * one / two);
          result = this.roundNumber(result, 2);

      var z=document.getElementById("form1");
        (<HTMLInputElement>document.getElementById("resultbox")).value= this.addSeparatorsNF(result, '.', '.', ',');
      if((<HTMLInputElement>document.getElementById("resultbox")).value== "NaN"){ (<HTMLInputElement>document.getElementById("resultbox")).value= ""; }
      //alert (result +"  "+ monthly);

    }

     revcalc2(x, b, c) {
    //alert (x +"  "+ b+"  "+c+"  "+d);
      var monthly = x;
      var i = b/(12 * 100);  //interest_rate
      var years = c;
      var down_payment = 0;
      
      var n = years * 12;
      var one = ( i * Math.pow((1 + i), n) );
      var two = ( Math.pow((1 + i), n) - 1 );
      var loan = (monthly * two / one) + down_payment;
          loan = this.roundNumber(loan, 2);

      var z=document.getElementById("form1");
        (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(loan, '.', '.', ',');
      if((<HTMLInputElement>document.getElementById("textA")).value == "NaN"){ (<HTMLInputElement>document.getElementById("textA")).value = ""; }
      //alert (result +"  "+ monthly);
    }
     xdelay3(value) {
      let str;
      var z=document.getElementById("form1"),
      //  a=z.textA.value, b=z.textB.value, c=z.textC.value, d=z.textD.value, x=z.resultbox.value;

       a = (<HTMLInputElement>document.getElementById("textA")).value,
       b = (<HTMLInputElement>document.getElementById("textB")).value,
       c = (<HTMLInputElement>document.getElementById("textC")).value,
       d = (<HTMLInputElement>document.getElementById("textD")).value,
       x = (<HTMLInputElement>document.getElementById("resultbox")).value;

      if (value == 'a'){str = a;}
      if (value == 'b'){str = b;}
      if (value == 'c'){str = c;}
      if (value == 'd'){str = d;}
      if (value == 'x'){str = x;} 
      
      str = this.stripcomma(str);

      if (value == 'a'){  (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'b'){  (<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'c'){  (<HTMLInputElement>document.getElementById("textC")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'd'){  (<HTMLInputElement>document.getElementById("textD")).value = this.addSeparatorsNF(str, '.', '.', ',');}
      if (value == 'x'){  (<HTMLInputElement>document.getElementById("resultbox")).value = this.addSeparatorsNF(str, '.', '.', ',');}

      if(value != 'a'){   setTimeout(()=>{this.revcall3()},200) }
    }


     revcall3() {
      var z=document.getElementById("form1"),
      //  a=z.textA.value, b=z.textB.value, c=z.textC.value, d=z.textD.value, x=z.resultbox.value;
      a = (<HTMLInputElement>document.getElementById("textA")).value,
      b = (<HTMLInputElement>document.getElementById("textB")).value,
      c = (<HTMLInputElement>document.getElementById("textC")).value,
      d = (<HTMLInputElement>document.getElementById("textD")).value,
      x = (<HTMLInputElement>document.getElementById("resultbox")).value;

      
     let bs = this.stripcomma(b);(<HTMLInputElement>document.getElementById("textB")).value = this.addSeparatorsNF(bs, '.', '.', ',');
     let cs = this.stripcomma(c); (<HTMLInputElement>document.getElementById("textC")).value= this.addSeparatorsNF(cs, '.', '.', ',');
     let ds = this.stripcomma(d); (<HTMLInputElement>document.getElementById("textD")).value = this.addSeparatorsNF(ds, '.', '.', ',');
     let xs = this.stripcomma(x); (<HTMLInputElement>document.getElementById("resultbox")).value= this.addSeparatorsNF(xs, '.', '.', ',');
        //alert (xs +"  "+ bs+"  "+cs);
        this.revcalc3(xs,bs,cs,ds);
    }

     revcalc3(x, b, c, d) {
    //alert (x +"  "+ b+"  "+c+"  "+d);
      var monthly = x;
      var i = b/1200;  //interest_rate
      var years = c;
      var down_payment = parseFloat(d); 
      var n = years * 12;

        //var loan = monthly*((Math.pow((1+i),n)-1)/i);
        var PV = 0;
        PV = down_payment + monthly*((1-(1/Math.pow((1+i),n)))/i);
        //alert (down_payment + " " + PV);
        var loan = PV*Math.pow((1+i),n);
        loan = this.roundNumber(loan, 2);
          
      var z=document.getElementById("form1");
        (<HTMLInputElement>document.getElementById("textA")).value = this.addSeparatorsNF(loan, '.', '.', ',');
      if((<HTMLInputElement>document.getElementById("textA")).value == "NaN"){ (<HTMLInputElement>document.getElementById("textA")).value = ""; }
    }  

}
