<div *ngIf="themeStyle === 'impian_emas'; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
  <div id="footer-holder1">
    <footer class="footer p-2 m-0 mt-5" [class]="footerClass" id="footer">
      <div id="footer-holder mb-5">
        <div class="content-desktop" id="footer-desktop">
          <div id="footer-cust">
            <div class="row" id="footer-content">
              <div class="col-sm-6" id="footer-add" [innerHTML]="footerContent">
              </div>
              <div class="col-sm-6 text-right">
                <div id="footer-right">
                  <div class="list-inline f-social-links">
                    <a class="list-inline-item" href="https://www.facebook.com/impianemas.my/" target="_blank"><i class="fa fa-facebook-f fa-2x"></i></a>
                    <a class="list-inline-item" href="https://www.instagram.com/impianemas.lifecircle" target="_blank"><i class="fa fa-instagram fa-2x"></i></a>
                  </div>
                </div>
                <div class="footer-links" id="footer-url">
                  <ul>
                    <li *ngFor="let url of footerLinks"><a [routerLink]="url.url">{{ url.name }}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-mobile" id="footer-mobile">
          <div id="footer-cust text-center">
            <div class="row" id="footer-content">
              <div class="col-sm-6" id="footer-add" [innerHTML]="footerContent">
              </div>
              <div class="col-sm-6 text-center">
                <div id="footer-right">
                  <div class="list-inline f-social-links">
                    <a class="list-inline-item" href="https://www.facebook.com/impianemas.my/" target="_blank"><i class="fa fa-facebook-f fa-2x"></i></a>
                    <a class="list-inline-item" href="https://www.instagram.com/impianemas.lifecircle" target="_blank"><i class="fa fa-instagram fa-2x"></i></a>
                  </div>
                </div>
                <div class="footer-links" id="footer-url">
                  <ul>
                    <li *ngFor="let url of footerLinks"><a [routerLink]="url.url">{{ url.name }}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 text-center border-top"> 
        <small class="text-muted" [innerHTML]="copyRight"></small>
      </div>
    </footer>
  </div>
</ng-template>
<ng-template #elseBlock>
  <footer class="footer" [class]="footerClass">
    <div class="container text-center">
      <nav class="navbar navbar-expand-sm navbar-light ">
        <div class="navbar-nav d-md-flex d-block flex-row mx-md-auto mx-0 d-inline-flex" id="footer-link">
          <a *ngFor="let url of footerLinks" class="nav-link" [routerLink]="url.url">{{ url.name }}</a>
        </div>
        <div *ngIf="errSpinner" class="spinner-border text-center"></div>
      </nav>
      <span class="text-muted" [innerHTML]="copyRight"></span>
    </div>
  </footer>
</ng-template>


<a href="https://wa.me/60197337521" id="whatsapp-btn" target="_blank"></a>