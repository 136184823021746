<div id="main-cont" class="d-flex flex-column min-vh-100">
  <div *ngIf="themeStyle ==='impian_emas' && pageTitle === 'Home'; then thenBlock else elseBlock"></div>
  <ng-template #thenBlock>
    <div *ngFor="let page of pageSections">
      <ng-template [ngIf]="page.name === 'Carousel'">
        <app-carousel></app-carousel>
      </ng-template>
    </div>
    <div id="impian-home">
      <ng-template [ngIf]="pageTitle === 'Home'">
        <div class="sec-title" id="project">
          <h3 class="text-center">OUR PROJECT</h3>
          <hr>
        </div>
        <app-project></app-project>
      </ng-template>
      <ng-template [ngIf]="pageTitle === 'Home'">
        <div class="sec-title" id="event">
          <h3 class="text-center">NEWS & EVENTS</h3>
          <hr>
        </div>
        <app-news-event></app-news-event>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #elseBlock>
    <ng-template [ngIf]="pageTitle !== 'Home'">

      <div *ngIf="themeStyle ==='impian_emas'; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <h1 class="text-center" id="impian-title">{{ pageTitle }}</h1>
      </ng-template>
      <ng-template #elseBlock>
        <h1 class="text-center" id="page-title">{{ pageTitle }}</h1>
      </ng-template>
    </ng-template>

    <section *ngFor="let page of pageSections">
      <ng-template [ngIf]="page.name === 'Privacy Notice'">
        <app-inquiry></app-inquiry>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Carousel'">
        <app-carousel></app-carousel>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Article' || page.section_type ==='Article'">
        <app-article></app-article>
      </ng-template>
      <!-- <ng-template [ngIf]="page.section_type.code === 'Article'">
            <app-article></app-article>
        </ng-template> -->
      <ng-template [ngIf]="page.name === 'Company Profile'">
        <app-article></app-article>
      </ng-template>



      <ng-template [ngIf]="page.name === 'Contact Us'">
        <app-contact></app-contact>
      </ng-template>


      <ng-template [ngIf]="page.name === 'Products'">
        <app-product></app-product>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Product List'">
        <app-product></app-product>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Category Menu'">
        <app-category-menu></app-category-menu>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Category List'">
        <app-category-list></app-category-list>
      </ng-template>
      <ng-template [ngIf]="page.name === 'View Product'">
        <app-product-view></app-product-view>
      </ng-template>
      <ng-template [ngIf]="page.name === 'View Product'">
        <app-product-view></app-product-view>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Inner Product'">
        <app-inner-link-product></app-inner-link-product>
      </ng-template>
      <!-- <ng-template [ngIf]="page.section_type.code === 'Inner Product'">
          <app-inner-link-product></app-inner-link-product>
        </ng-template> -->
      <ng-template [ngIf]="page.name === 'Product Category'">
        <app-product-category [catLink]="catLink"></app-product-category>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Brand Listing'">
        <app-brands></app-brands>
      </ng-template>
      <ng-template [ngIf]="page.name === 'brands'">
        <app-brand-view></app-brand-view>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Article Listing'">
        <app-article-list></app-article-list>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Item List'">
        <app-article-list></app-article-list>
      </ng-template>
      <ng-template [ngIf]="page.name === 'articles'">
        <app-article-view></app-article-view>
      </ng-template>
      <ng-template [ngIf]="page.name === 'Article View'">
        <app-article-view></app-article-view>
      </ng-template>
      <!-- <ng-template [ngIf]="page.page.section_type.code === 'Article View'">
          <app-article-view></app-article-view>
        </ng-template> -->
      <ng-template [ngIf]="page.name === 'Categories'">
        <app-category></app-category>
      </ng-template>
      <ng-template [ngIf]="page.name === 'link category'">
        <app-inner-link-categories></app-inner-link-categories>
      </ng-template>
      <ng-template [ngIf]="page.name === 'News '">
        <app-article-list></app-article-list>
      </ng-template>
      <ng-template [ngIf]="page.name === '404'">
        <app-page-not-found></app-page-not-found>
      </ng-template>
    </section>
  </ng-template>
</div>

