<!-- <div class="container">
  <form class="form-inline">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="Date"
                   name="dp" [(ngModel)]="model1" ngbDatepicker #d1="ngbDatepicker" id="date">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="Date of Birth"
                   name="dp" [(ngModel)]="model2" ngbDatepicker #d2="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">

    </div>
  </form>
</div> -->

<div class="row" id="contact">
  <div class=" col-md-12 col-lg-8">
    <app-inquiry-form></app-inquiry-form>
  </div>
  <div class="col-lg-4">
    <h3>Loan Payment Calculator</h3>

    <!--<iframe src="http://www.frograte.com/calculator.asp?t=1" height="170" width="300" frameborder="0" allowtransparency="true"></iframe>-->
    <div style="height: auto; width: 300px;">
      <form id="form1" action="">
        <table class="table table-sm table-borderless" width="235" border="0" cellpadding="2">
          <tbody>
            <tr>
              <td style="height: 22px">Home Price</td>
              <td>$</td>
              <td style="height: 22px">
                <input name="text" type="text" class="form-control" id="textA"
                  onfocus="this.className='form-control boxFocus'" (keyup)="xdelay('a')" value="750,000" size="15"
                  autocomplete="off">
              </td>
            </tr>
            <tr>
              <td>Interest Rate<br>(% per year)</td>
              <td>
              </td>
              <td><input name="text2" type="text" class="form-control" id="textB"
                  onfocus="this.className='form-control boxFocus'" (keyup)="xdelay('b')" value="6" size="5"
                  autocomplete="off"></td>
            </tr>
            <tr>
              <td>Years</td>
              <td>
              </td>
              <td><input name="text3" type="text" class="form-control" id="textC"
                  onfocus="this.className='form-control boxFocus'" (keyup)="xdelay('c')" value="30" size="5"
                  autocomplete="off"></td>
            </tr>
            <tr>
              <td>Down Payment</td>
              <td>
                $</td>
              <td><input name="text4" type="text" class="form-control" id="textD" (keyup)="xdelay('d')" value="0"
                  size="15" autocomplete="off"></td>
            </tr>
            <tr>
              <td>Monthly Payment</td>
              <td>
                $</td>
              <td><input name="text5" type="text" class="form-control" id="resultbox" (keyup)="xdelay('x')"
                  value="4,496.63" size="15"></td>
            </tr>
          </tbody>
        </table>
        <p style="font-size:x-small"><br>Change any combination of fields to calculate the monthly payment. Or change
          the
          down payment and monthly payment to calculate the possible home price.</p>
      </form>
    </div>
    <br /><br />

    <h2>Bank Loan</h2>

    <p><a href="https://www.maybank2u.com.my/" target="_blank"><img alt="" height="40" src="../../assets/image/maybank2u.png" width="180" /></a><br />
    <a href="https://www.pbebank.com" target="_blank"><img alt="" src="../../assets/image/publicbank.png" /></a><br />
    <a href="https://www.affinbank.com.my" target="_blank"><img alt="" src="../../assets/image/affinbank.png" /></a><br />
    <a href="https://www.hlb.com.my" target="_blank"><img alt="" src="../../assets/image/hongleong.png" /></a><br />
    <a href="https://www.cimbbank.com.my" target="_blank"><img alt="" height="40" src="../../assets/image/cimb.png" width="180" /></a><br />
    <a href="https://www.rhbgroup.com/" target="_blank"><img alt="" src="../../assets/image/rhb.png" /></a><br />
    <a href="https://www.ambankgroup.com" target="_blank"><img alt="" src="../../assets/image/ambank.png" /></a><br />
    <a href="https://www1.uob.com.my" target="_blank"><img alt="" height="40" src="../../assets/image/uob.png" width="180" /></a><br />
    <a href="https://www.mbsb.com.my" target="_blank"><img alt="" src="../../assets/image/mbsb.png" /></a><br />
    <a href="https://www.ocbc.com.my" target="_blank"><img alt="" src="../../assets/image/ocbc.png" /></a><br />
    <a href="https://www.hsbc.com.my" target="_blank"><img alt="" src="../../assets/image/hsbc.png" /></a><br />
    <a href="https://www.citibank.com.my/" target="_blank"><img alt="" height="40" src="../../assets/image/citybank.png" width="180" /></a></p>

    <p>&nbsp;</p>

    <h3>EPF Information</h3>

    <p><a href="http://www.kwsp.gov.my/" target="_blank"><img alt="" height="107" src="../../assets/image/epf.gif" width="167" /></a></p>

  </div>
</div>

