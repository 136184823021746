import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-news-event',
  templateUrl: './news-event.component.html',
  styleUrls: ['./news-event.component.css']
})
export class NewsEventComponent implements OnInit {

  newsdata: any;
  newsarticles = [];
  newsContents: any;
  newsContentsTwo: any;

  latest = [];
  artitle = '';
  artbody = '';
  arturl = '';

  constructor(private newsArticle: ContentService) { }

  ngOnInit(): void {
    this.getNews();
  }

  getNews() {
    this.newsdata = this.newsArticle.getPageContent();
    console.log(this.newsdata);
    for (const news of this.newsdata) {
      if (news.name === 'Latest News & Event') {
        this.newsContents = news.contents[0];
        console.log("newconentss", this.newsContents);
      }
      if (news.name === 'Another News Item') {
        this.newsContentsTwo = news.contents[0];
        console.log("secondconentss", this.newsContentsTwo);
      }
    }
  }
}
