<section>
  <div class="event-news">
    <div class="content-desktop news-desktop">
      <div id="news-event">
        <div class="row text-center ml-0 mr-0" id="event">
          <div class="col event-cust article">
            <h4 id="news-title">{{ newsContents.title }}</h4>
            <div class="news-art-img text-center">
              <img [src]="newsContents.image" alt="">
            </div>
            <div id="news">
              <div class="art-cust pr-3 pl-3" [innerHTML]="(newsContents.body |  slice:0:300) + '...'">
              </div>
              <a [routerLink]="newsContents.view_url">Read More >></a>
            </div>
          </div>
          <div class="col event-cust article">
            <h4 id="news-title">{{ newsContentsTwo.title }}</h4>
            <div class="news-art-img text-center">
              <img class="img-fluid" [src]="newsContentsTwo.image" alt="">
            </div>
            <div id="news">
              <div class="art-cust pr-3 pl-3" [innerHTML]="(newsContentsTwo.body |  slice:0:300) + '...'">
              </div>
              <a [routerLink]="newsContentsTwo.view_url">Read More >></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-mobile news-article">
      <div id="news-event">
        <div class="row text-center" id="event">

          <div class="col-lg event-cust article">
            <h4 id="news-title">{{ newsContents.title }}</h4>
            <div class="news-art-img text-center">
              <img [src]="newsContents.image" alt="">
            </div>
            <div id="article-cust">
              <div class="text-center">

                <div class="text-center art-cust" [innerHTML]="(newsContents.body |  slice:0:300) + '...'">
                </div>
                <a [routerLink]="newsContents.view_url">Read More >></a>

              </div>
            </div>

            <h4 id="news-title">{{ newsContentsTwo.title }}</h4>
            <div class="news-art-img text-center">
              <img [src]="newsContentsTwo.image" alt="">
            </div>
            <div id="article-cust">
              <div class="text-center">

                <div class="text-center art-cust" [innerHTML]="(newsContentsTwo.body |  slice:0:250) + '...'">
                </div>
                <a [routerLink]="newsContentsTwo.view_url">Read More >></a>


              </div>
            </div>



          </div>

        </div>
      </div>
    </div>
  </div>
</section>