import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../service/header.service';
import { LanguagesService } from '../service/languages.service';
import { GlobalConstants } from '../common/global-constants';
import { SiteInfoService } from '../service/site-info.service';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  langauage: any;
  languages = [];

  headerData: any;
  headerLinks = [];
  monitorLinks = [];
  headerName = [];
  menuClass = '';
  headerLogo = '';
  loadingSpinner = false;
  pageName = '';
  linkName = '';
  isCollapsed: boolean;
  language: any;
  langType = [];
  langIcon = '';
  langCode = '';

  levelTwoLinks = [];
  indexTwoLinks = [];

  levelFiveLinks = [];
  linkLevelTwo = [];
  linkLevelThree = [];
  linkLevelFour = [];
  linkLevelFive = [];

  menuVisibility = 0;
  siteInfo: any;
  themeStyle = 'default';

  constructor(
    private data: HeaderService,
    private lang: LanguagesService,
    private visibility: SiteInfoService,
    private linkService: ContentService,
    private languageService: LanguagesService) { }

  ngOnInit(): void {
    this.loadingSpinner = false;
    this.getLanguage();

    this.visibility.getSiteInfo()
      .subscribe(resdata => {
        this.siteInfo = resdata;
        this.menuVisibility = this.siteInfo.site_info.header_menu_visibility;
        this.themeStyle = this.siteInfo.site_info.theme.code;
        // console.log(this.themeStyle);
      });

    this.data.getHeader()
      .subscribe(resdata => {
        this.headerData = resdata;
        this.headerLinks = this.headerData.header.links;
        console.log("prateek", this.headerLinks);
        this.menuClass = this.headerData.header.class;
        this.headerLogo = this.headerData.header.brand_image_url;
        this.headerName = this.headerData.header.links.name;

        this.checkRoute(this.headerLinks);

        // console.log(this.headerLinks);
        for (const [i, subLinks] of this.headerLinks.entries()) {
          console.log(subLinks.level_two_links);
          const result = subLinks.level_two_links.length;
          if (result > 0) {
            this.levelTwoLinks.push(subLinks.level_two_links);

          }
        }
        this.indexTwoLinks = this.levelTwoLinks[2];
        console.log(this.indexTwoLinks);
      });
    this.isCollapsed = true;

    this.language = this.lang.getLang()
      .subscribe(langdata => {
        this.langType.push(langdata);
        this.langIcon = this.langType[0].languages[0].icon;
        this.langCode = this.langType[0].languages[0].code;
      });

    // test level 5 links
    this.levelFiveLinks = GlobalConstants.level5;
    this.levelFiveLinks = this.levelFiveLinks[0].header.links;
    // console.log(this.levelFiveLinks);
  }

  checkRoute(links) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < links.length; i++) {
      GlobalConstants.pages.push(links[i].name);
    }
  }

  getLanguage() {
    this.languageService.getLang()
      .subscribe(resdata => {
        this.language = resdata;
        this.languages = this.language.languages;
        // console.log(this.language.languages);
      });
  }

  changeLang(val) {
    console.log(val);
  }
}
