<div class="col-sm">
    <h3>Privacy Notice</h3>
    <p class="text-justify">This Privacy Notice outlines how Gunung Impian Development Sdn Bhd and our related,
        affiliated and subsidiaries
        either
        in or out of Malaysia (the terms “we” or “us” or “our” refer to Gunung Impian Development Sdn Bhd) collects,
        uses,
        maintains and discloses your personal data in respect of commercial transactions and how we safeguard your
        personal
        data.</p>
    <p>Pursuant to the Personal Data Protection Act 2010 (“the Act”), we are required by the Act to provide you a
        privacy
        notice which covers:-</p>
    <ol>
        <li>the personal data which we collect from you;</li>
        <li>the purpose of collecting the personal data from you</li>
        <li>how do we collect the personal data from you</li>
        <li>your right to request access and correct your personal data</li>
        <li>class of third parties to whom your personal data will be disclosed</li>
        <li>the choices that you have to limit the processing of the personal data; and</li>
        <li>whether it is obligatory or voluntary for you to furnish the personal data and the consequences</li>
    </ol>
    
    <p><span>Please click (in <strong><a href="https://staging.nixsercloud.com/uploads/companies/40/files/notice_to_customers_in_eng.pdf" target="_blank">English version</a></strong>, <strong><a href="https://staging.nixsercloud.com/uploads/companies/40/files/notice_to_customers_in_bm.pdf" target="_blank">BM version</a></strong> and <strong><a href="https://staging.nixsercloud.com/uploads/companies/40/files/notice_to_customers_in_ch.pdf" target="_blank">Chinese version</a></strong>) to view the full terms of our Privacy Policy for more information and full knowledge of the usage by us. We advise you to review the full terms of our Privacy Notice prior to providing us with your personal data. &nbsp;</span></p>

    <p><br />
    <span>Thank you.</span></p>

    <h3 class="text-center">Priority Registration Form</h3>

    <form #f="ngForm" (ngSubmit)="submit(f)">

        <div class="row">
           <div class="form-group col-md-6">
                <label class="enquirytext" for="date">Date</label>
                <input type="date" ngModel id="date" name="date" class="form-control" placeholder="mm/dd/yyyy"
                    #name="ngModel" required >
            </div> 
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label class="enquirytext" for="title">Title</label>
                <select class="form-control selectdiv" id="title" name="title" required>
                    <option>--select--</option>
                    <option>Tan sri</option>
                    <option>Paun sri</option>
                    <option>Dato</option>
                    <option>Datin</option>
                    <option>Dr</option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Ms</option>
                </select>
            </div> 

            <div class="form-group col-md-8">
                <label class="enquirytext" for="name">Name</label>
                <input type="text" ngModel id="name" name="name" class="form-control" placeholder="Enter name"
                    #name="ngModel" required>
            </div> 

            <div class="form-group col-md-6">
                <label class="enquirytext" for="ic">I/C No</label>
                <input type="text" ngModel id="ic" name="ic" class="form-control"
                    placeholder="Enter IC No (New)" #name="ngModel"  required>
            </div> 
            <div class="form-group col-md-6">
                <label class="enquirytext" for="date-of-birth">Date Of Birth</label>
                <input type="date" ngModel id="date-of-birth" name="date_of_birth" class="form-control"
                    placeholder="mm/dd/yyyy" #name="ngModel" required>
            </div> 

            <div class="form-group col-md-12">
                <label class="enquirytext" for="correspondence-address">Correspondence Address</label>
                <input type="text" ngModel id="correspondence-address" name="correspondence_address" class="form-control" placeholder="Enter address"
                    #name="ngModel" required >
            </div>

            <div class="form-group col-md-6">
                <label class="enquirytext" for="country">Country</label>
                <select class="form-control selectdiv" id="country" name="country" required>
                    <option>--select--</option>
                    <option>Malaysia</option>
                    <option>Singapore</option>
                </select>
            </div> 

            <div class="form-group col-md-6">
                <label class="enquirytext" for="email">Email</label>
                <input required type="email" ngModel id="email" name="email" class="form-control" placeholder="Enter email address"
                    #name="ngModel">
            </div>

            <div class="form-group col-md-4">
                <label class="enquirytext" for="phone-number">Tel</label>
                <input required type="text" ngModel id="phone-number" name="phone_number" class="form-control" placeholder="Enter phone number"
                    #name="ngModel">
            </div>

            <div class="form-group col-md-4">
                <label class="enquirytext" for="mobile-number">Mobile</label>
                <input required type="text" ngModel id="mobile-number" name="mobile_number" class="form-control" placeholder="Enter mobile number"
                    #name="ngModel">
            </div>

            <div class="form-group col-md-4">
                <label class="enquirytext" for="home-number">Home</label>
                <input required type="text" ngModel id="home-number" name="home_number" class="form-control" placeholder="Enter home contact number"
                    #name="ngModel">
            </div>

            <div class="form-group col-md-4">
                <label class="enquirytext" for="nationality">Nationality</label>
                <select class="selectdiv" name="nationality" id="nationality" required>
                    <option value="">--select--</option>
                    <option value="Malaysian">Malaysian</option>
                    <option value="Singaporean">Singaporean</option>
                    <option value="Others">Others</option>
                </select>
            </div>

            <div class="form-group col-md-4">
                <label class="enquirytext" for="race">Race</label>
                <select class="selectdiv" name="race" id="race" required>
                    <option value="">--select--</option>
                    <option value="Malay">Malay</option>
                    <option value="Chinese">Chinese</option>
                    <option value="Indian">Indian</option>
                    <option value="others">Others</option>
                </select>
            </div>

            <div class="form-group col-md-4">
                <label class="enquirytext" for="marital-status">Marital Status</label>
                <select class="selectdiv" name="marital_status" id="marital-status" required>
                    <option>--select--</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Others">Others</option>
                </select>
            </div>

            <div class="form-group col-md-6">
                <label class="enquirytext" for="monthly-household-income">Monthly Household Income</label>
                <select class="selectdiv" name="monthly_household_income" id="monthly-household-income" required>
                    <option value="">--select--</option>
                    <option value="Below RM3,500">Below RM3,500</option>
                    <option value="RM3,501 - RM5,000">RM3,501 - RM5,000</option>
                    <option value="RM5,001 - RM8,000">RM5,001 - RM8,000</option>
                    <option value="RM8,001 - RM15,000">RM8,001 - RM15,000</option>
                    <option value="RM15,001 - RM20,000">RM15,001 - RM20,000</option>
                    <option value="RM20,001 & above">RM20,001 & above</option>                   
                </select>
            </div> 

            <div class="form-group col-md-6">
                <label class="enquirytext" for="source-of-financing">Source Of Financing</label>
                <select class="selectdiv" name="source_of_financing" id="source-of-financing" required>
                    <option value="">--select--</option>
                    <option value="Bank Loan">Bank Loan</option>
                    <option value="Government">Government</option>
                    <option value="Self-financing">Self-financing</option>
                    <option value="Others">Others</option>
                </select>
            </div>

            <hr class="col-2 text-center"/>
            <div class="form-group col-md-12">
                <label class="enquirytext">Preferred Type Of Property</label><br>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="preferred[]" value="2-Storey Terrace House">
                  <label class="form-check-label enquirytext" for="inlineCheckbox1">2-Storey Terrace House</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="preferred[]" value="2-Storey Semi-Detached">
                  <label class="form-check-label enquirytext" for="inlineCheckbox2">2-Storey Semi-Detached</label>
                </div>  
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="preferred[]" value="Apartment / Condominium">
                  <label class="form-check-label enquirytext" for="inlineCheckbox2">Apartment / Condominium</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="preferred[]" value="2-Storey Detached (Bungalow)">
                  <label class="form-check-label enquirytext" for="inlineCheckbox2">2-Storey Detached (Bungalow)</label>
                </div>    
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="preferred[]" value="2-Storey / 3-Storey Shop Office">
                  <label class="form-check-label enquirytext" for="inlineCheckbox2">2-Storey / 3-Storey Shop Office</label>
                </div>  
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="preferred[]" value="Factory">
                  <label class="form-check-label enquirytext" for="inlineCheckbox2">Factory</label>
                </div>            
            </div>

            <div class="form-group col-md-12">
                <label class="enquirytext" for="remarks">Remarks</label>
                <textarea class="form-control" id="remarks" name="remarks" rows="3"></textarea>
            </div>

            <div class="form-group col-md-6">
                <label class="enquirytext" for="budget">Budget</label>
                <select class="selectdiv" id="budget" name="budget" required>
                    <option value="">--select--</option>
                    <option value="RM300,001 - RM500,000">RM300,001 - RM500,000</option>
                    <option value="RM500,001 - RM800,000">RM500,001 - RM800,000</option>
                    <option value="RM800,001 - RM1 Million">RM800,001 - RM1 Million</option>
                    <option value="RM1 Million and above">RM1 Million and above</option>
                </select>
            </div> 

            <div class="form-group col-md-6">
                <label class="enquirytext" for="how-know">How did you come to know about our properties?</label>
                <select class="selectdiv" id="how-know" name="how_know">
                    <option value="">--select--</option>
                    <option value="Billboard / Hoarding">Billboard / Hoarding</option>
                    <option value="Flyer">Flyer</option>
                    <option value="Friend">Friend</option>
                    <option value="Website">Website</option>
                    <option value="Magazine">Magazine</option>
                    <option value="Newspaper">Newspaper</option>
                    <option value="Others">Others</option>
                </select>
            </div>
        </div>

        <p>
            By completing this Priority Registration Form, I hereby understand, acknowledge and expressly give to
            consent Gunung
            Impian Development Sdn Bhd and/or its affiliates and/or its subsidiaries to collect and process my Personal
            Data in
            order to enable them to keep me informed of any updates-information-events-products-services with regards to
            Gunung
            Impian Development Sdn Bhd.
        </p>
        <div class="row">
            <p class="col-12 m-0">Note</p>
            <p class="col-12 noteText">*Registration is valid for a period of one year only, afterwhich it will be
                auto-deleted.
                Booking is based on a
                first-come-first-serve basis.
                *Please ensure all information provided is accurate. The acceptance of this form does not guarantee or
                imply that the
                property indicated will be allocated to you.</p>

        </div>

        <button class="btn btn-secondary shadow buttonn ">Submit</button>
    </form>
</div>